import { useState } from 'react';
import { useFlags } from 'launchdarkly-react-client-sdk';
import clsx from 'clsx';
import { Link, Tooltip, Box, TableRow, TableCell, Typography } from '@mui/material';
import moment from 'moment';
import theme from 'theme';
import { styled } from '@mui/material/styles';
import VisibilitySharpIcon from '@mui/icons-material/VisibilitySharp';

import { CommonCheckboxComponent } from 'shared';
import { useNavigate } from 'react-router-dom';
import { DeleteReportGroupModal } from './DeleteGroupModal';
import { ShowAdvisories } from './ShowAdvisories';
import ReportStatusInfo from './ReportStatusInfo';
import { makeStyles } from '@mui/styles/';
import { GetReportDetailById, setSelectedReports } from 'redux/actions';
import { useDispatch } from 'react-redux';
import { useSimulationHook } from 'utils/hooks';
import { REPORT_STATUS } from 'constants/network';
import { REPORT_FILE_TYPE, REPORT_SUB_TYPE } from 'constants/reports';
import { LazyLoadCustom } from './LazyLoadCustom';
import FolderSpecialSharpIcon from '@mui/icons-material/FolderSpecialSharp';
import FolderSharpIcon from '@mui/icons-material/FolderSharp';
import palette from 'theme/palette';
import InsightsIcon from '@mui/icons-material/Insights';
import { FolderWithDollarIcon, FolderWithStarIcon, FolderWithGearwheelIcon } from 'assets/images';

const customTypoStyled = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    width: 200
};

const ParentAnalysisName = styled(Typography)(({ hideUnderline }) => ({
    cursor: 'pointer',
    textDecoration: hideUnderline ? 'none' : 'underline',
    ...customTypoStyled
}));

const StyledTableCell = styled(TableCell)(() => ({
    borderLeft: `1px solid ${theme.palette.ODBlue.odBlue9}`,
    padding: 'none'
}));

const NetworkTypeWrapper = styled(Box)(() => ({
    display: 'flex',
    alignItems: 'center'
}));

const ReportLink = styled(Link)(({ disabled }) => ({
    cursor: 'pointer',
    color: disabled ? theme.palette.neutral.neutral6 : theme.palette.common.black,
    marginRight: '5px'
}));

export const ReportRunRow = ({
    idx,
    item,
    editReport,
    onRowChecked,
    getReportRuns,
    tabValue,
    latestBaseReportId,
    disabled = false
}) => {
    const { newSimulationFlow, bidAnalysis, odpt3963Network, networkManagerRateStudyColumn } =
        useFlags();

    const useStyles = makeStyles((theme) => ({
        tableRow: {
            borderLeft: 'none',
            marginRight: 16,
            height: 40,
            '&>th': {
                color: theme.palette.common.black,
                borderTop: `1px solid ${theme.palette.neutral.neutral1}`,
                borderBottom: '0px'
            },
            '&>td': {
                color: disabled ? theme.palette.neutral.neutral6 : theme.palette.common.black,
                borderTop: `1px solid ${theme.palette.neutral.neutral1}`,
                borderBottom: '0px',
                borderLeft: 'none'
            },
            '&:hover': {
                backgroundColor: theme.palette.neutral.neutral1
            }
        },
        isChecked: {
            backgroundColor: theme.palette.ODLightBlueNeutral.lightBlue1,
            '&:hover': {
                backgroundColor: theme.palette.ODLightBlueNeutral.lightBlue1
            }
        },
        isDisabled: {
            backgroundColor: theme.palette.neutral.neutral1,
            fontWeight: 400,
            color: theme.palette.neutral.neutral6,
            '&:hover': {
                backgroundColor: theme.palette.neutral.neutral1
            }
        },
        alignCenter: {
            display: 'flex !important',
            alignItems: 'center'
        },
        ellipsis: {
            whiteSpace: 'nowrap',
            overflow: 'hidden',
            textOverflow: 'ellipsis'
        }
    }));

    const classes = useStyles();
    const dispatch = useDispatch();
    const { handleOverrideSimulation } = useSimulationHook();
    const [advisories, toggleAdvisories] = useState(false);
    const [confirmDelete, toggleConfirmDelete] = useState(false);
    const navigate = useNavigate();
    const isAllStatusTabSelected = tabValue === 'all';

    const selectBucket = (item) => navigate(`?bucketId=${item.bucket}`);

    const isUniqueBaseReport = idx === 'base_report' ?? false;

    const rowNetworkType =
        item?.report_status === REPORT_STATUS.DRAFT
            ? item?.isBidAnalysis
                ? REPORT_FILE_TYPE.BID
                : REPORT_FILE_TYPE.NETWORK
            : item?.file_type.toLowerCase();

    const viewReport = (item, sidebar = null) => {
        const isBidAnalysisReport = rowNetworkType === REPORT_FILE_TYPE.BID;
        if (newSimulationFlow && item.report_status === REPORT_STATUS.FAILED)
            handleOverrideSimulation(
                undefined,
                item.configuration,
                item.bucket,
                isBidAnalysisReport
            );
        else if (
            [REPORT_STATUS.COMPLETED, REPORT_STATUS.DRAFT, REPORT_STATUS.VFA_COMPLETED].includes(
                item.report_status
            )
        ) {
            dispatch(GetReportDetailById(item.id));
            dispatch(
                setSelectedReports({
                    selectedBaseReport: {
                        id: item.id,
                        enabled: true
                    },
                    selectedCompareReport: {}
                })
            );

            const redirectUrl = `/reports/${
                item.bucket ? `bucket/${item.bucket}/` : `report-run/`
            }${item.id}${item.config_id ? `/config/${item.config_id}` : ''}/network/shipper${
                sidebar ? `/${sidebar}/` : '/'
            }`;

            const bidAnalysisUrl = item.config_id
                ? `/bid-analysis/${item.id}/config/${item.config_id}`
                : `/bid-analysis/${item.id}`;

            navigate(isBidAnalysisReport && bidAnalysis ? bidAnalysisUrl : redirectUrl, {
                report_run_id: item.id
            });
        }
    };

    const setIconByNetworkType = (type = REPORT_FILE_TYPE.BASE, file_sub_type) => {
        const latestReportIconColor =
            isUniqueBaseReport && !disabled
                ? palette.semantic.focusedBlue
                : disabled
                ? palette.neutral.neutral6
                : palette.black;

        const latestBaseIconByFF = odpt3963Network ? (
            <Box marginLeft="2px" display="flex" alignItems="center" marginRight="12px">
                <FolderWithStarIcon style={{ color: latestReportIconColor }} />
            </Box>
        ) : (
            <FolderSpecialSharpIcon
                sx={{
                    marginRight: '8px',
                    color: latestReportIconColor
                }}
            />
        );

        switch (type) {
            case REPORT_FILE_TYPE.BASE:
                return (
                    <NetworkTypeWrapper>
                        {latestBaseIconByFF}
                        Base Network
                    </NetworkTypeWrapper>
                );
            case REPORT_FILE_TYPE.NETWORK:
                return (
                    <NetworkTypeWrapper>
                        {odpt3963Network ? (
                            <Box
                                marginLeft="2px"
                                display="flex"
                                alignItems="center"
                                marginRight="12px">
                                <FolderWithGearwheelIcon style={{ color: palette.black }} />
                            </Box>
                        ) : (
                            <FolderSharpIcon sx={{ marginRight: '8px' }} />
                        )}
                        Network Analysis
                    </NetworkTypeWrapper>
                );
            case REPORT_FILE_TYPE.BID:
                if (odpt3963Network) {
                    const isBidFinalized = file_sub_type === REPORT_SUB_TYPE.FINALIZED;

                    return (
                        <NetworkTypeWrapper>
                            {isBidFinalized ? (
                                <InsightsIcon sx={{ marginRight: '8px' }} />
                            ) : (
                                <Box display="flex" marginRight="8px" alignItems="center">
                                    <FolderWithDollarIcon />
                                </Box>
                            )}
                            {isBidFinalized && 'Finalized '}
                            Bid Analysis
                        </NetworkTypeWrapper>
                    );
                }
                return (
                    <NetworkTypeWrapper>
                        <FolderSharpIcon sx={{ marginRight: '8px' }} />
                        Bid Analysis
                    </NetworkTypeWrapper>
                );

            default:
                return (
                    <NetworkTypeWrapper>
                        <FolderSpecialSharpIcon sx={{ marginRight: '8px' }} />
                        Base Network
                    </NetworkTypeWrapper>
                );
        }
    };

    const momentFormat = moment(item.created_at, 'MM/DD HH:mm').format('MM/DD HH:mm');
    const subType = item?.file_sub_type?.toLowerCase();
    const parentReportName = item?.parent_report?.name ?? '-';
    const isParentReportEmpty = parentReportName === '-';

    const handleParentAnalysisAction = (item) => {
        const ReportFileType = item?.file_type;
        const ReportFileSubType = item?.file_sub_type;

        if (ReportFileType === REPORT_FILE_TYPE.BASE) return;
        if (
            ReportFileType === REPORT_FILE_TYPE.BID &&
            ReportFileSubType === REPORT_SUB_TYPE.FINALIZED
        ) {
            navigate(`/bid-analysis/${item?.parent_report?.id}`);
            return;
        }
        navigate(`/reports/bucket/${item?.bucket}/${item?.parent_report?.id}/network/shipper`);
    };

    return (
        <>
            <TableRow
                id={`table-row-for-${idx}`}
                component={LazyLoadCustom}
                key={idx}
                className={clsx(
                    item?.isChecked && !disabled
                        ? classes.isChecked
                        : disabled && classes.isDisabled,
                    classes.tableRow,
                    `report-item-${item.report_status.replace(/ /g, '_')}-${idx}`
                )}>
                <StyledTableCell
                    sx={{
                        fontWeight: isUniqueBaseReport && !disabled ? 600 : 400
                    }}
                    padding="none">
                    <Box display="flex" spacing={1} direction="row" alignItems="center">
                        <span
                            style={{
                                padding: 0
                            }}
                            className={classes.alignCenter}>
                            <CommonCheckboxComponent
                                color={theme.palette.neutral.neutral6}
                                isChecked={item.isChecked}
                                checkedColor={theme.palette.common.black}
                                onClick={(event) => onRowChecked(item, event.target.checked)}
                                className={`reports-runs-checkbox-${idx}-${item.isChecked}`}
                                isDisabled={disabled}
                            />
                        </span>
                        {item.read_only && (
                            <span className={classes.alignCenter} style={{ paddingRight: 8 }}>
                                <Tooltip title={item.name || 'N/A'} placement="bottom-start">
                                    <VisibilitySharpIcon
                                        sx={{
                                            color: theme.palette.common.black
                                        }}
                                    />
                                </Tooltip>
                            </span>
                        )}
                        <Box className={clsx(classes.ellipsis, classes.alignCenter)}>
                            <Tooltip
                                title={
                                    item.read_only
                                        ? `${item.name} (read_only)`
                                        : `${item.name || 'N/A'}`
                                }
                                placement="bottom-start">
                                <ReportLink
                                    disabled={disabled}
                                    className={clsx(
                                        `report-details report-runs-${item.report_status}-item`,
                                        `report-run-${(item?.name || '').replace(/ /g, '_')}`
                                    )}
                                    onClick={() => !disabled && viewReport(item)}>
                                    <Box
                                        width={200 + (item.read_only ? 0 : 36)}
                                        sx={{ textDecoration: !disabled && 'underline' }}
                                        className={classes.ellipsis}>
                                        {item.name || 'N/A'} {item.read_only && <i>(read_only)</i>}
                                    </Box>
                                </ReportLink>
                            </Tooltip>
                        </Box>
                    </Box>
                </StyledTableCell>
                {bidAnalysis && (
                    <StyledTableCell
                        sx={{
                            fontWeight: isUniqueBaseReport && !disabled ? 600 : 400,
                            ...customTypoStyled
                        }}
                        padding="none">
                        {setIconByNetworkType(rowNetworkType, subType)}
                    </StyledTableCell>
                )}
                {odpt3963Network && (
                    <>
                        <StyledTableCell
                            onClick={() => handleParentAnalysisAction(item)}
                            sx={{
                                fontWeight: isUniqueBaseReport && !disabled ? 600 : 400
                            }}
                            padding="none">
                            <Tooltip
                                placement="right"
                                disableHoverListener={isParentReportEmpty}
                                title={parentReportName}>
                                <ParentAnalysisName hideUnderline={isParentReportEmpty}>
                                    {parentReportName}
                                </ParentAnalysisName>
                            </Tooltip>
                        </StyledTableCell>

                        {networkManagerRateStudyColumn && (
                            <StyledTableCell
                                sx={{
                                    fontWeight: isUniqueBaseReport && !disabled ? 600 : 400
                                }}
                                padding="none">
                                <Tooltip placement="right" title={item?.rate_study}>
                                    <Typography sx={{ ...customTypoStyled }}>
                                        {item?.rate_study}
                                    </Typography>
                                </Tooltip>
                            </StyledTableCell>
                        )}
                    </>
                )}

                <StyledTableCell
                    sx={{
                        fontWeight: isUniqueBaseReport && !disabled ? 600 : 400,
                        ...customTypoStyled
                    }}
                    padding="none">
                    {momentFormat ?? '-'}
                </StyledTableCell>
                <StyledTableCell
                    sx={{ fontWeight: isUniqueBaseReport && !disabled ? 600 : 400 }}
                    padding="none">
                    <Box className={clsx(classes.ellipsis, classes.alignCenter)}>
                        <Tooltip
                            title={
                                item.read_only
                                    ? `${item.bucketName} (read_only)`
                                    : `${item.bucketName || 'N/A'}`
                            }
                            placement="bottom-start">
                            <Link
                                style={{
                                    cursor: 'pointer',
                                    color: disabled
                                        ? theme.palette.neutral.neutral6
                                        : theme.palette.common.black,
                                    marginRight: '5px'
                                }}
                                className={classes.ellipsis}
                                onClick={() => selectBucket(item)}>
                                <Box
                                    sx={{
                                        ...customTypoStyled,
                                        width: 200 + (item.read_only ? 0 : 36),
                                        textDecoration: !disabled && 'underline',
                                        cursor: 'pointer',
                                        color: disabled
                                            ? theme.palette.neutral.neutral6
                                            : theme.palette.common.black
                                    }}>
                                    {item.bucketName || 'N/A'}
                                </Box>
                            </Link>
                        </Tooltip>
                    </Box>
                </StyledTableCell>
                {isAllStatusTabSelected && (
                    <StyledTableCell
                        sx={{ fontWeight: isUniqueBaseReport && !disabled ? 600 : 400 }}
                        padding="none">
                        <ReportStatusInfo
                            darkIcon={true}
                            classes={classes}
                            status={item.report_status}
                        />
                    </StyledTableCell>
                )}
                <StyledTableCell
                    sx={{ fontWeight: isUniqueBaseReport && !disabled ? 600 : 400 }}
                    padding="none">
                    <Tooltip title={item?.user_email}>
                        <Typography sx={{ ...customTypoStyled }}>
                            {item?.user_email ?? '-'}
                        </Typography>
                    </Tooltip>
                </StyledTableCell>
                <StyledTableCell
                    padding="none"
                    style={{
                        position: 'relative'
                    }}>
                    <ShowAdvisories
                        id={item?.id}
                        latestBaseReportId={latestBaseReportId}
                        rowNetworkType={rowNetworkType}
                        classes={classes}
                        advisories={advisories}
                        onHistory={() => viewReport(item, 'change-history')}
                        status={item.report_status}
                        onEdit={() => editReport(item)}
                        toggleAdvisories={toggleAdvisories}
                        onDelete={() => toggleConfirmDelete(true)}
                        disabled={disabled}
                    />
                </StyledTableCell>
            </TableRow>

            {confirmDelete && (
                <DeleteReportGroupModal
                    records={[item]}
                    open={confirmDelete}
                    title="Confirm Report Deletion"
                    fetchData={getReportRuns}
                    prefix={<b>Report Name:</b>}
                    onAction={() => toggleConfirmDelete(false)}
                    onClose={() => toggleConfirmDelete(false)}
                />
            )}
        </>
    );
};
