import api from '@flatfile/api';
import { FlatfileRecord } from '@flatfile/plugin-record-hook';
import API from '../../../utils/axios';

export const POSITIVE_NUMBER_WITH_UP_TO_TWO_DECIMALS = /^(?!0(\.0{1,2})?$)[0-9]\d*(\.\d{1,2})?$/;
export const NONNEGATIVE_DOLLAR_VALUE = /^\d+(\.\d{1,2})?$/;
export const POSITIVE_NUMERIC_CHARACTER_ONLY = /^(?!0(\.0{1,2})?$)[0-9]\d*$/;
export const NUMERIC_CHARACTER_BETWEEN_3_AND_5 = /^(?!^\d{3,5}$).*/;
export const ALPHA_CHARACTER_ONLY_MIN_3_MAX_50 = /^(?![A-Za-z]{3,50}$).*/;
export const ALPHA_CHARACTER_ONLY_MIN_2_MAX_60 = /^(?![A-Za-z]{2,60}$).*/;
export const NULL_VALUES = /^(?!(^\s*$|^null$|^NULL$|^None$|^NONE$)).*/;

export const updateFlatfileSpaceMetadata = async (spaceId: string, data: any) => {
  try {
    await api.spaces.update(spaceId, {
      metadata: {
        flatfileSubmitData: data
      }
    }
  );
  } catch (error) {
    console.error("Error updating space metadata:", error);
  }
};

interface ZipValidationResult {
  flatfile_response: [ResponseInfo, number][];
  locations: { zip_code: string }[];
}
interface CityStateValidationResult {
  flatfile_response: [ResponseInfo, number][];
  locations: { city: string, state: string, zip_code: string }[];
}

interface MileageValidationResult {
  mileage: [ResponseInfo, number][];
}

interface ResponseInfo {
  info: [{
      level: 'warning' | 'error' | 'info';
      message: string;
  }];
  value: string;
}

export const validateMileageField = async (
  records: FlatfileRecord[]
): Promise<FlatfileRecord[]> => {
  const originZips = records.map((record, index) => [
      record.get("origin_zip"),
      index + 1,
  ]);
  const destinationZips = records.map((record, index) => [
      record.get("destination_zip"),
      index + 1,
  ]);
  const mileages = records.map((record, index) => [
      record.get("mileage"),
      index + 1,
  ]);

  const payload = {
      origin_zip: originZips,
      destination_zip: destinationZips,
      mileage: mileages,
  };

  let validationResults: MileageValidationResult;
  try {
      const response = await API.post<MileageValidationResult>(
          "/bidding/flatfile/validate/mileage/",
          payload
      );
      validationResults = response.data;
  } catch (error) {
      console.error("Error validating mileage data:", error);
      validationResults = { mileage: [] };
  }

  const formattedRecords = records.map((record, index) => {
      const flatfileResponse = validationResults.mileage[index];

      const [responseInfo] = flatfileResponse;
      const { info, value } = responseInfo as ResponseInfo;

      if (info[0]) {
          switch (info[0].level) {
              case "warning":
                  record.addWarning("mileage", info[0].message);
                  record.set("mileage", value);
                  break;

              case "error":
                  record.addError("mileage", info[0].message);
                  break;

              default:
                  record.set("mileage", value);
          }
      }

      return record;
  });

  return formattedRecords;
};

export const validateZipCodeFields =  async (
  field: string,
  records: FlatfileRecord[],
  zipCodeCliffordValidation: boolean
): Promise<FlatfileRecord[]> => {
  const fieldValues = records.map((record, index) => [
  record.get(field),
  index + 1,
  ]);
  const path = zipCodeCliffordValidation ? "/bidding/flatfile/validate/location/zip-code/" : "/bidding/flatfile/validate/location/zip-code-syntax/"


  let validationResults: ZipValidationResult;
  try {
  const response = await API.post<ZipValidationResult>( path, {
      zip_codes: fieldValues,
  });
  validationResults = response.data;
  } catch (error) {
      console.error(`Error validating ${field} data:`, error);
      validationResults = { flatfile_response: [], locations: [] }; 
  }

  const formattedRecords = records.map((record, index) => {
  const flatfileResponse = validationResults.flatfile_response.find(
      ([responseInfo, resultIndex]: [ResponseInfo, number]) =>
      resultIndex === index + 1
  );

  if (flatfileResponse) {
      const [responseInfo] = flatfileResponse;
      const { info, value } = responseInfo;

      switch (info[0].level) {
      case "warning":
          record.addWarning(field, info[0].message);
          record.set(field, value);
          break;

      case "error":
          record.addError(field, info[0].message);
          break;

      default:
          console.warn(`Unexpected info level: ${info[0].level}`);
      }
  } else {
      const locationData = validationResults.locations[index];
      if (locationData?.zip_code) record.set(field, locationData.zip_code);
  }

  return record;
  });

  return formattedRecords;
};

export const conditionallyRequiredWithoutAll = ( field: any, fieldsToCheck: string[], record: FlatfileRecord ) => {
  const allOtherFieldsEmpty = fieldsToCheck.every((field: string) => !record.get(field));

  const fieldList = fieldsToCheck.join(', ');
  const plural = fieldsToCheck.length > 1;
  const fieldWord = plural ? 'fields' : 'field';
  const verbWord = plural ? 'are' : 'is';

  const errorString = `This field is required when the ${fieldList} ${fieldWord} ${verbWord} empty.`;

  if (allOtherFieldsEmpty && !record.get(field)){
      record.addError(field, errorString);
  }
}

export const validateRegexMatches = (field: string, regex: RegExp, errorString: string, record: FlatfileRecord) => {
  const value = record.get(field);
  if(typeof value === 'string' && !regex.test(value)) record.addError(field, errorString)
}

export const formatNumberFieldsTwoDecimals = (record: FlatfileRecord) => {
  const fieldsToFormat = ['load_volume', 'rate_per_mile', 'other_revenue', 'other_revenue_per_mile'];

  fieldsToFormat.forEach(field => {
      const val = record.get(field);
      if (val !== null && val !== undefined && Number(val) !== 0) {
          record.set(field, Number(val).toFixed(2));
      }
  });
}

export const validateCityStateFields =  async (
  field: string,
  records: FlatfileRecord[],
): Promise<FlatfileRecord[]> => {
  const fieldValues = records.map((record, index) => [
  record.get(field),
  index + 1,
  ]);
  const path = "/bidding/flatfile/validate/location/city-state/";

  let validationResults: CityStateValidationResult;
  try {
  const response = await API.post<CityStateValidationResult>( path, {
    city_state: fieldValues,
    validation_type: 'city_state'
  });
  validationResults = response.data;
  } catch (error) {
      console.error(`Error validating ${field} data:`, error);
      validationResults = { flatfile_response: [], locations: [] }; 
  }

  const formattedRecords = records.map((record, index) => {
  const flatfileResponse = validationResults.flatfile_response.find(
      ([responseInfo, resultIndex]: [ResponseInfo, number]) =>
      resultIndex === index + 1
  );

  if (flatfileResponse) {
      const [responseInfo] = flatfileResponse;
      const { info, value } = responseInfo;

      switch (info[0].level) {
      case "warning":
          record.addWarning(field, info[0].message);
          record.set(field, value);
          break;

      case "error":
          record.addError(field, info[0].message);
          break;

      default:
          console.warn(`Unexpected info level: ${info[0].level}`);
      }
  } else {
      const locationData = validationResults.locations[index];
      if (locationData?.city) record.set(field, `${locationData.city}, ${locationData.state}`);
  }

  return record;
  });

  return formattedRecords;
};
